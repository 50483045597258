<template>
    <div id="app">
        <div class="body fixed mobile">
            <!-- class: fixed, mobile -->
            <!-- Loading Spinner -->
            <!-- <div class="spinner"><div><div></div></div></div> -->
            <!-- Header -->
            <section class="header point_history">
                <a
                    class="icon iconButton third"
                    @click.prevent="goBackTo"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-chevron-left"
                        size="lg"
                    ></font-awesome-icon>
                </a>
                <span class="fL">交易紀錄</span>
                <div class="icon iconButton"></div>
            </section>
            <!-- Main -->
            <section class="main point_history">
                <!-- Tabs -->
                <div class="tabs line fM rounded">
                    <a
                        v-if="enableConsumptionRecord" 
                        class="tab flexV width center"
                        @click.prevent="$router.push('/payment-record')"
                    >
                        <span class="fS">消費紀錄</span>
                    </a>
                    <div v-if="enableConsumptionRecord" class="lineV"></div>
                    <a 
                        class="tab flexV width center"
                        @click.prevent="$router.push('/record')"
                    >
                        <span class="fS">點數紀錄</span>
                    </a>
                    <div class="lineV"></div>
                    <a class="tab flexV width center active" @click.prevent>
                        <span class="fS">點數到期日</span>
                    </a>
                </div>
                <!-- Filter -->
                <div class="filter flexH width">
                    <a
                        class="flexH width middle rounded"
                        @click.prevent="filterPopOpen = true"
                    >
                        <span class="fM">{{ filterByStatus }}</span>
                        <div class="icon third">
                            <font-awesome-icon icon="fa-solid fa-caret-down" size="xs" />
                        </div>
                    </a>
                </div>
                <!-- 紀錄 -->
                <div class="records flexV width padding">
                    <div
                        class="record flexH width between margin padding"
                        v-for="(pointPeriod, index) in displayedPoints"
                        :key="'point' + index"
                    >
                        <span class="fL"
                            >{{ tsToDate(pointPeriod.endTs) }}到期</span
                        >
                        <div class="flexH">
                            <!-- <div class="img">
                                <img src="@/assets/icon/point.svg" />
                            </div> -->
                            <span class="fL bold primary">{{
                                pointPeriod.pointBalance
                            }}</span>
                        </div>
                    </div>
                    <div class="lineH"></div>
                </div>
            </section>
            <section class="footer point_history">
                <a
                    class="button rounded"
                    @click.prevent="$router.push('/scanner')"
                >
                    <div class="icon">
                        <img src="@/assets/icon/scanner.svg" />
                    </div>
                    <span class="fM">使用紅利點數</span>
                </a>
            </section>
        </div>
        <!-- Popup (下拉選單) -->
        <FilterPopup
            v-if="filterPopOpen"
            @closePopup="filterPopOpen = false"
            v-slot="pop"
        >
            <section class="popHeader">
                <span class="fL">類型</span>
            </section>
            <section class="popMain" @click.prevent="pop.close">
                <a
                    @click.prevent="changeStatusFilter('即將到期')"
                    :class="{active: filterByStatus === '即將到期'}"
                >
                    <span>即將到期</span>
                </a>
                <a
                    @click.prevent="changeStatusFilter('已經到期')"
                    :class="{active: filterByStatus === '已經到期'}"
                >
                    <span>已經到期</span>
                </a>
            </section>
        </FilterPopup>
    </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState, mapGetters } from "vuex";
import FilterPopup from '@/components/FilterPopup.vue';

export default {
    name: "PointList",
    components: { FilterPopup },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            points: [
                {
                    pointBalance: 0,
                    pointPeriods: []
                }
            ],
            filterByStatus: "即將到期",
            filterPopOpen: false,
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        if (from.path === '/profile') {
            to.meta.parent = '/profile';
        } else if (from.path === '/' || from.path === '/record' || from.path === '/point-list' || from.path === '/payment-record') {
            to.meta.parent = localStorage.getItem('parentPath') || '/home';
        } else {
            to.meta.parent = '/home';
        }
        localStorage.setItem('parentPath', to.meta.parent);
        
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                next("/error");
            }
        });
    },
    created() {
        this.getUserPoints()
            .then((res) => {
                this.$store.commit("updateUserPoints", res);
                this.points = JSON.parse(JSON.stringify(this.userPoints));
                console.log("this.points: ", this.points);
            })
            .catch((err) => {
                console.log("err: ", err);
            });
    },
    mounted() {},
    computed: {
        ...mapState(["user"]),
        ...mapGetters({
            userPoints: "getTyUserPoints"
        }),
        enableConsumptionRecord() {
            return process.env.VUE_APP_DISABLE_CONSUMPTION_RECORD?.trim() === 'true' ? false : true;
        }, 
        displayLastestEndDateOfPoints() {
            return this.lastestEndOfPoint ? this.tsToDate(this.lastestEndOfPoint.endTs) : "";
            // if (
            //     this.points.pointPeriods &&
            //     this.points.pointPeriods.length > 0
            // ) {
            //     return this.tsToDate(this.points.pointPeriods[0].endTs);
            // } else {
            //     return "";
            // }
        },
        displayLastestEndOfPoints() {
            return this.lastestEndOfPoint ? this.lastestEndOfPoint.pointBalance : 0;
            // if (
            //     this.points.pointPeriods &&
            //     this.points.pointPeriods.length > 0
            // ) {
            //     return this.points.pointPeriods[0].pointBalance;
            // } else {
            //     return 0;
            // }
        },
        displayPointBalance() {
            return this.userPoints && this.userPoints.pointBalance
                ? this.userPoints.pointBalance
                : 0;
        },
        displayedPoints() {
            if (!this.points.pointPeriods) return [];
            return this.filterByStatus === '即將到期'
                ? this.points.pointPeriods
                    .filter(p => p.endTs * 1000 > Date.now())
                    .sort((a, b) => a.endTs - b.endTs)
                : this.points.pointPeriods
                    .filter(p => p.endTs * 1000 <= Date.now())
                    .sort((a, b) => a.endTs - b.endTs);
        },
        lastestEndOfPoint() {
            if (
                this.points &&
                this.points.pointPeriods &&
                this.points.pointPeriods.length > 0
            ) {
                let sorted = [...this.points.pointPeriods];
                sorted.sort(function(a, b) {
                    return a.endTs - b.endTs;
                })
                const latestEndOfPoint = sorted.find(p => p.endTs >= moment().unix());
                return latestEndOfPoint;
            }
            return null;
        }
    },
    methods: {
        goBackTo() {
            let parent = this.$route.meta.parent || '/home';
            this.$router.push(parent);
        },
        tsToDate(ts) {
            return moment(ts * 1000).format("YYYY/MM/DD");
        },
        dummyUserPoints() {
            let result = [
                {
                    merchantId: "tycard2022",
                    pointCode: "1",
                    pointName: "桃園紅桃",
                    pointBalance: 85,
                    pointPeriods: [
                        {
                            startTs: "1649520000",
                            endTs: "1655654399",
                            periodId: "P:2",
                            pointBalance: 85,
                            pointName: "桃園紅桃",
                            pointIssuanceId: 1
                        },
                        {
                            startTs: "1649520000",
                            endTs: "1655654397",
                            periodId: "P:2",
                            pointBalance: 84,
                            pointName: "桃園紅桃",
                            pointIssuanceId: 1
                        },
                        {
                            startTs: "1649520000",
                            endTs: "1655654398",
                            periodId: "P:2",
                            pointBalance: 83,
                            pointName: "桃園紅桃",
                            pointIssuanceId: 1
                        }
                    ]
                }
            ];
            return new Promise((resolve) => {
                resolve({data: result});
            });
        },
        callUserPointsAPI() {
            var config = {
                method: "get",
                url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
                headers: {}
            };

            return this.$http(config)
        },
        getUserPoints() {
            // return this.dummyUserPoints()
            return this.callUserPointsAPI()
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
        changeStatusFilter(status) {
            this.filterByStatus = status;
        },
    }
};
</script>
